<template>
  <span class="tw-web-checkmark" :class="{ checked, error, disabled, byWrapper }"></span>
</template>

<script lang="ts" setup>
import type { PageOptions } from '@shared/types/model';
import { computed, type PropType } from 'vue';
const props = defineProps({
  checked: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  byWrapper: { type: Boolean, default: false },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const checkMarkStyle = computed(() => {
  return {
    borderColor: props.pageOptions?.colors?.theme?.[0],
    backgroundColor: props.pageOptions?.colors?.theme?.[0],
    textColor: props.pageOptions?.colors?.text?.[0]
  };
});

const checkMarkBackground = computed(() => {
  const color = encodeURIComponent(props.pageOptions?.colors?.theme?.[0]);
  return `url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='${color}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.36642 7.71666C4.27753 7.71666 4.1942 7.70266 4.11642 7.67466C4.03865 7.64711 3.96642 7.6 3.89976 7.53333L1.03309 4.66666C0.910867 4.54444 0.852423 4.386 0.857756 4.19133C0.863534 3.99711 0.927534 3.83888 1.04976 3.71666C1.17198 3.59444 1.32753 3.53333 1.51642 3.53333C1.70531 3.53333 1.86087 3.59444 1.98309 3.71666L4.36642 6.1L10.0164 0.449996C10.1386 0.327774 10.2971 0.266663 10.4918 0.266663C10.686 0.266663 10.8442 0.327774 10.9664 0.449996C11.0886 0.572218 11.1498 0.73044 11.1498 0.924663C11.1498 1.11933 11.0886 1.27777 10.9664 1.4L4.83309 7.53333C4.76642 7.6 4.6942 7.64711 4.61642 7.67466C4.53865 7.70266 4.45531 7.71666 4.36642 7.71666Z' /%3E%3C/svg%3E%0A")` 
});
</script>

<style lang="postcss">
.tw-web-checkmark {
  --border-color: v-bind('checkMarkStyle.borderColor');
  --background-color: v-bind('checkMarkStyle.backgroundColor');
  --text-color: v-bind('checkMarkStyle.textColor');
  --checkmark-background-color: v-bind('checkMarkBackground');

  @apply inline-block h-20 w-20 flex-[0_0_20px] rounded-4 border-2 border-neutral-200 bg-center bg-no-repeat transition-colors;

  &.error:not(.disabled) {
    @apply border-error-500;
  }
}

*:not(:disabled):hover + .tw-web-checkmark,
*:not(:disabled):hover > .byWrapper.tw-web-checkmark,
.tw-web-checkmark:not(.disabled):hover {
  &:not(.error) {
    @apply bg-primary-100;
    border-color: var(--border-color, '#429CC');
    color: var(--text-color, '#429CC');
  }

  &.error {
    @apply border-error-500 bg-error-100 text-error-700;
  }
}

*:not(:disabled):focus + .tw-web-checkmark,
*:not(:disabled):focus > .byWrapper.tw-web-checkmark,
.tw-web-checkmark:not(.disabled):focus {
  @apply bg-white bg-no-repeat ring-4 ring-offset-0;

  &:not(.error) {
    @apply ring-primary-100;
    border-color: var(--border-color, '#429CC');
    background-color: var(--background-color, '#41929f');
  }

  &.error {
    @apply border-error-500 text-error-500 ring-error-100;
  }
}

*:not(:disabled):focus:hover + .tw-web-checkmark,
*:not(:disabled):focus:hover > .byWrapper.tw-web-checkmark,
.tw-web-checkmark:not(.disabled):focus:hover {
  &:not(.error) {
    @apply bg-primary-100;
  }

  &.error {
    @apply bg-error-100;
  }
}

*:not(:disabled):checked + .tw-web-checkmark,
*:not(:disabled):checked > .byWrapper.tw-web-checkmark,
*:not(:disabled) > .byWrapper.tw-web-checkmark.checked,
.tw-web-checkmark:not(.disabled).checked,
.byWrapper.tw-web-checkmark:not(.disabled).checked {
  @apply bg-[length:12px];
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.36642 7.71666C4.27753 7.71666 4.1942 7.70266 4.11642 7.67466C4.03865 7.64711 3.96642 7.6 3.89976 7.53333L1.03309 4.66666C0.910867 4.54444 0.852423 4.386 0.857756 4.19133C0.863534 3.99711 0.927534 3.83888 1.04976 3.71666C1.17198 3.59444 1.32753 3.53333 1.51642 3.53333C1.70531 3.53333 1.86087 3.59444 1.98309 3.71666L4.36642 6.1L10.0164 0.449996C10.1386 0.327774 10.2971 0.266663 10.4918 0.266663C10.686 0.266663 10.8442 0.327774 10.9664 0.449996C11.0886 0.572218 11.1498 0.73044 11.1498 0.924663C11.1498 1.11933 11.0886 1.27777 10.9664 1.4L4.83309 7.53333C4.76642 7.6 4.6942 7.64711 4.61642 7.67466C4.53865 7.70266 4.45531 7.71666 4.36642 7.71666Z' /%3E%3C/svg%3E%0A");

  &:not(.error) {
    border-color: var(--border-color, '#429CC');
    background-color: var(--background-color, '#41929f');
  }

  &.error {
    @apply border-error-500 bg-error-500;
  }
}

*:not(:disabled):checked:hover + .tw-web-checkmark,
*:not(:disabled):checked:hover > .byWrapper.tw-web-checkmark,
*:not(:disabled):hover > .byWrapper.tw-web-checkmark.checked,
.byWrapper.tw-web-checkmark:not(.disabled).checked:hover,
.tw-web-checkmark:not(.disabled).checked:hover {
  &:not(.error) {
    border-color: var(--border-color, '#429CC');
    background-color: var(--background-color, '#41929f');
  }

  &.error {
    @apply border-error-700 bg-error-700;
  }
}

*:not(:disabled):checked:focus + .tw-web-checkmark,
*:not(:disabled):checked:focus > .byWrapper.tw-web-checkmark,
*:not(:disabled):focus > .byWrapper.tw-web-checkmark.checked,
.tw-web-checkmark:not(.disabled).checked:focus {
  &:not(.error) {
    @apply bg-primary-100;
    background-image: var(--checkmark-background-color);
  }

  &.error {
    @apply bg-error-100;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='%23E13023' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.36642 7.71666C4.27753 7.71666 4.1942 7.70266 4.11642 7.67466C4.03865 7.64711 3.96642 7.6 3.89976 7.53333L1.03309 4.66666C0.910867 4.54444 0.852423 4.386 0.857756 4.19133C0.863534 3.99711 0.927534 3.83888 1.04976 3.71666C1.17198 3.59444 1.32753 3.53333 1.51642 3.53333C1.70531 3.53333 1.86087 3.59444 1.98309 3.71666L4.36642 6.1L10.0164 0.449996C10.1386 0.327774 10.2971 0.266663 10.4918 0.266663C10.686 0.266663 10.8442 0.327774 10.9664 0.449996C11.0886 0.572218 11.1498 0.73044 11.1498 0.924663C11.1498 1.11933 11.0886 1.27777 10.9664 1.4L4.83309 7.53333C4.76642 7.6 4.6942 7.64711 4.61642 7.67466C4.53865 7.70266 4.45531 7.71666 4.36642 7.71666Z' /%3E%3C/svg%3E%0A");
  }
}

*:not(:disabled):checked:focus:hover + .tw-web-checkmark,
*:not(:disabled):checked:focus:hover > .byWrapper.tw-web-checkmark,
*:not(:disabled):focus:hover > .byWrapper.tw-web-checkmark.checked,
.tw-web-checkmark:not(.disabled).checked:focus:hover {
  &.error {
    @apply border-error-500;
  }
}

*:checked:disabled + .tw-web-checkmark,
*:checked:disabled > .byWrapper.tw-web-checkmark,
*:disabled > .byWrapper.tw-web-checkmark.checked,
.tw-web-checkmark.checked.disabled {
  @apply bg-neutral-100;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='%23C3C9D5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.36642 7.71666C4.27753 7.71666 4.1942 7.70266 4.11642 7.67466C4.03865 7.64711 3.96642 7.6 3.89976 7.53333L1.03309 4.66666C0.910867 4.54444 0.852423 4.386 0.857756 4.19133C0.863534 3.99711 0.927534 3.83888 1.04976 3.71666C1.17198 3.59444 1.32753 3.53333 1.51642 3.53333C1.70531 3.53333 1.86087 3.59444 1.98309 3.71666L4.36642 6.1L10.0164 0.449996C10.1386 0.327774 10.2971 0.266663 10.4918 0.266663C10.686 0.266663 10.8442 0.327774 10.9664 0.449996C11.0886 0.572218 11.1498 0.73044 11.1498 0.924663C11.1498 1.11933 11.0886 1.27777 10.9664 1.4L4.83309 7.53333C4.76642 7.6 4.6942 7.64711 4.61642 7.67466C4.53865 7.70266 4.45531 7.71666 4.36642 7.71666Z' /%3E%3C/svg%3E%0A");
}
</style>
